.buttonBack {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  outline: 0;
  transition: opacity .2s;
  user-select: none;
  opacity: 0.5;
  &:hover {
    opacity: 1
  }
}
