@import "../../../scss/constants";
@import "../../../scss/typography";
@import "../../../scss/colors";


.container {
  display: flex;
  flex-direction: column;
  padding: 0 $grid-base-value * 2;
  min-height: $grid-base-value * 6;

  //&_date {
  //  min-width: 200px;
  //}

  //&_duration {
  //  min-width: 153px;
  //}
  //@media (max-width: 1400px) {
  //  margin: 15px 0;
  //}
}

.title {
  color: rgba(255, 255, 255, 0.5);
  //margin-bottom: 10px;
  //font-size: 0.8125rem;
  font-size: $font-size-0;
  line-height: 1;
  margin-top: $grid-base-value / 2;
  margin-bottom: $grid-base-value;
}

.number {
  font-size: $font-size-0;
  line-height: 1;
  color: $fg-color;
}
