@import "src/next/scss/constants";

.container {
  display: contents;
}

.chart-line {
  grid-column: 1 / span 8;
  display: flex;
  padding-left: $grid-base-value;
}

.chart-pie {
  grid-column: 9 / span 4;
  display: flex;
  padding-right: $grid-base-value;
}

$default-column-width: minmax(100px, 221px);

.table {
  grid-column: 1 / -1;
  padding: 0 $grid-base-value;
}

.table-channels {
  grid-template-columns: 64px auto repeat(5, auto)
}
