.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.form-inline .form-group {
  flex: 1 1;
  margin-bottom: 0;
  margin-right: 20px;
}

.form-inline .button {
  margin-left: 20px;
}


