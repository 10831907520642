.root {
  .title {
    color: #ebecf0;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }

  .description {
    color: #ebecf0;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-top: 6px;
  }

  .options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 28px;

    @media screen and (max-width: 700px) {
      grid-gap: 10px;
      margin-top: 24px;
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 567px) {
    padding: 6px 12px 12px;
  }
}

.icon {
  width: 24px;
  cursor: pointer;
  height: 24px;
}

.secondaryButton {
  color: #ff7354 !important;
  box-shadow: inset 0 0 0 2px #ff7354 !important;

  &:hover {
    color: #ebecf0 !important;
    background: #ff5630 !important;
    box-shadow: inset 0 0 0 2px #ff5630 !important;
  }
}