@import "../../scss/typography";
@import "../../scss/constants";
@import "../../scss/colors";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
}

.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: $grid-base-value * 4;

  .key {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: $grid-base-value;
  }

  .value {
    margin-left: $grid-base-value / 2;
    font-size: $font-size-2;
    color: $fg-color;
  }
}

