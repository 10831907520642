@import "../../scss/constants";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    margin: $grid-base-value * 2;
  }
}
