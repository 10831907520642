@import "@restream/styles/scss/media";

.container {
  display: flex;
  height: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: #091e42;
  color: grey;
  border-left: 1px solid #253858;
  padding: 20px 50px 20px 30px;

  @include xs {
    display: none;
  }
}

.link {
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0;
  color: #97a0af;
  transition: color 100ms;

  &.active {
    color: #fb5028;
  }

  &.disabled {
    opacity: .5;
  }

  &.bigger {
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 20px;
  }
}

a.link {
  &:hover:not(.disabled) {
    color: #fb5028;
  }
}

.logoTitle {
  color: white;
  font-size: 20px;
  position: relative;
  margin-bottom: 20px;
}
