.block {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #253858;
  height: 540px;
  padding: 30px 30px 30px 30px;
  position: relative;
  &__noHeight {
    height: initial;
  }
}
.title {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.6;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 8px;
}
