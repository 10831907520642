@import "../../scss/typography";
@import "../../scss/constants";
@import "../../scss/colors";

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: fit-content(40%) $grid-base-value * 60 auto;
  grid-gap: $grid-base-value;
  padding-bottom: $grid-base-value * 2;

  .header {
    grid-column: 1 /  span 12;
  }

  .chart-line {
    grid-column: 1 /  span 8;
    padding-left: $grid-base-value;
    display: flex;
  }

  .chart-line.fill {
    grid-column: 1 /  span 12;
    padding-right: $grid-base-value;
  }

  .chart-pie {
    grid-column: 9 /  span 4;
    padding-right: $grid-base-value;
    display: flex;
  }

  .chart-phrases {
    grid-column: 1 /  span 6;
    padding-left: $grid-base-value;
    display: flex;
  }

  .chart-chatters-list {
    grid-column: 7 /  span 6;
    padding-right: $grid-base-value;
    display: flex;
  }

  .table {
    grid-column: 1 /  span 12;
    padding: 0 $grid-base-value;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $grid-base-value;
  font-size: $font-size--1;
  background: #9e1212;
  text-align: center;
}

.error-view {
 grid-column: 1 / -1;
}

.onbView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.callout {
  background: white;
  color: #182b4e;
  padding: 16px;
  margin: 8px;
  border-radius: 4px;
}
