.chart-count {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 10px;
  min-height: 24px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;

  .chart-percent {
    font-size: 1.2rem;
    line-height: 1.666;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  .chart-percent_up {
    color: #54ba73;
  }

  .chart-percent_down {
    color: #fc8471;
  }

  .icon-status {
    display: flex;
    align-items: center;
    margin-left: 8px;
    position: relative;
    margin-right: 4px;
  }
}

.container-percentage {
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:first-child {
    margin-right: 10px;
  }

  > *:last-child {
    font-size: 14px;
  }
}
