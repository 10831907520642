.title-page {
  font-size: 24px;
  line-height: 1;
  color: #ebedf2;
  letter-spacing: .5px;
  margin-bottom: 22px;
  margin-top: 12px;
  font-weight: 400;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.selectors {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.nav-block {
  display: flex;
}
