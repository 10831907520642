@import "src/next/scss/constants";
@import "src/next/scss/colors";


input.checkbox[type=checkbox] {
  width: $grid-base-value * 2.5;
  height: $grid-base-value * 2.5;
  background: $bg-color;
  appearance: none;
  outline: none;
  border: 1px solid $fg-color-darker-1;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  transition: all 200ms;
  //border-radius: $grid-base-value * 1.5;
}

input.checkbox.checkbox__round {
  border-radius: 8px;
}

input.checkbox[type=checkbox]:hover {
  background: $bg-color-lighten-1
}

input.checkbox[type=checkbox]:disabled {
  cursor: not-allowed;
}

input.checkbox[type=checkbox]:checked:after {
  position: absolute;
  content: "\2713";
  font-size: $grid-base-value * 2;
  font-weight: 500;
  width: $grid-base-value * 2;
  height: $grid-base-value * 2;
  color: $fg-color-darker-1;
  background: transparent;
  bottom: 2px;
  left: 2px;
}

input.checkbox[type=checkbox].checkbox__round:checked:after {
  content: "\2022";
  font-size: 17px;
  bottom: 0;
  left: 4px;
}
