@import "src/next/scss/constants";

.container {
  display: contents;
}

.chart {
  grid-column: 1 / -1;
  display: flex;
  padding: 0 $grid-base-value;
}

$default-column-width: minmax(100px, 221px);

.table {
  grid-column: 1 / -1;
  padding: 0 $grid-base-value;
}

.table-channels {
  grid-template-columns: 64px auto repeat(2, $default-column-width)
}
