@import "src/next/scss/typography";
@import "src/next/scss/colors";
@import "src/next/scss/constants";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.link-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: $grid-base-value - 2px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  opacity: .5;

  &:hover {
    opacity: 1;
  }
}

.title {
  display: flex;
  flex: 1;
  justify-content: center;

  h5 {
    font-size: $font-size-1;
    color: $fg-color-darker-1;
    margin: 0;
    line-height: normal;
  }
}

.value {
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;

  span {
    font-size: $font-size-3;
    font-weight: 500;
  }
}

.percentage-container {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
}

.percentage {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $grid-base-value;

  span {
    margin-left: $grid-base-value;
    font-size: $font-size-1;
  }
}

.error {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-top: $grid-base-value * 2;
    font-size: $font-size--1;
    text-align: center;
    color: $error-color;
    line-height: normal;
  }
}

