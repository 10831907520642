.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex: 0 1 auto;
  margin-left: auto;
  margin-right: auto;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &-fluid {
    width: 100%;
  }

}


