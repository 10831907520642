@import "src/next/scss/constants";

.container {
  display: contents;
}

.chart-bitrate {
  grid-column: 1 / span 6;
  display: flex;
  padding-left: $grid-base-value;
}

.chart-buffer {
  grid-column: 7 / span 6;
  display: flex;
  padding-right: $grid-base-value;
}

.channels {
  grid-column: 1 / -1;
  padding: 0 $grid-base-value;
}

.table {
  grid-template-columns: 64px auto repeat(4, auto);
}
