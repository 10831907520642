.container {
  margin-left: 8px;
}

.main {
  display: grid;
  grid-template-columns: minmax(auto, 80%);
  padding-bottom: 60px;
  margin-left: 8px;
}

.button {
  display: flex;
  justify-content: center;
  button {
    font-size: 12px !important;
  }
}
