@import "src/next/scss/constants";

.container {
  display: contents;
}

.chart-chatters {
  grid-column: 1 /  span 12;
  display: flex;
  padding-right: $grid-base-value;
}

$default-column-width: minmax(100px, 221px);

.table {
  grid-column: 1 / -1;
  padding: 0 $grid-base-value;
}

.table-channels {
  grid-template-columns: min-content 1fr repeat(1, $default-column-width);
}
