@import "../../next/scss/constants";

.DayPickerNavigation_button__default {
  border: 1px solid #1f385a;
  background-color: #1f385a;
  color: #757575;
}

.DayPickerNavigation_button__default:active {
  background: none;
  border: 1px solid #c1cad7;
}

.DayPickerNavigation_button__default:active .DayPickerNavigation_svg__horizontal {
  fill: white;
}

.DayPicker__horizontal {
  background: #253858;
}

.DayPicker_weekHeader {
  color: #ebecf0;
}

.DayPickerKeyboardShortcuts_panel {
  background: #1f385a;
  border: 1px solid #112b4f;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid rgba(250, 85, 46, 0.65);
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid rgba(250, 85, 46, 0.85);
}

.DateRangePickerInput {
  background: #253858;
}

.DateRangePickerInput__withBorder {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #253858;
}

.DateRangePickerInput_arrow {
  color: #ebecf0;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #ebecf0;
  height: 24px;
  width: 24px;
}

.DateRangePicker_picker {
  background: #253858;
  color: #ebecf0;
}

.DateInput_fangShape {
  fill: #1f385a;
}

.DateInput_fangStroke {
  stroke: #1f385a;
  fill: transparent;
}

.DateInput {
  background: none;
}

.DateInput_input {
  background: #253858;
  color: #ebecf0;
  text-align: center;
  font-size: 16px;
  font-family: 'Graphik', sans-serif;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.DateInput_input__focused {
  background: #253858;
  color: #ebecf0;
  border-bottom: 2px solid #fa552e;
}

.CalendarMonthGrid {
  background: #253858;
}

.CalendarMonth {
  background: #253858;
  color: #ebecf0;
}

.CalendarMonth_caption {
  color: #ebecf0;
  padding-top: $grid-base-value * 4;
  padding-bottom: $grid-base-value * 5;
  font-size: 16px;
}

.CalendarDay__default {
  background: #253858;
  color: #ebecf0;
  border: 1px solid #ffffff24;
}

.CalendarDay__default:hover {
  background: rgba(164, 204, 255, 0.83);
  border: 1px solid rgba(164, 204, 255, 0.83);
  color: inherit;
}

.CalendarDay__outside {
  background: rgba(37, 56, 88, 0.71);
  color: #ebecf069;
}

.CalendarDay__selected_span {
  background: rgba(250, 85, 46, 0.3);
  color: #ebecf0;
  border: 1px solid rgba(250, 85, 46, 0.2);
}

.CalendarDay__selected {
  background: rgba(250, 85, 46, 0.87);
  border: 1px solid rgba(250, 85, 46, 0.87);
  color: #ebecf0;
}

.CalendarDay__selected:hover {
  background: rgba(164, 204, 255, 0.83);
  color: white;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background: rgba(250, 85, 46, 0.65);
}
