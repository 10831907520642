.chart-loader {
  display: flex;
  flex: 1 1;
  width: 100%;
  background: rgba(19, 39, 69, 0.75);
  filter: blur(1px);
  -webkit-filter: blur(1px);
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
}
