@import '../../scss/constants';
@import '../../scss/typography';
@import "@restream/styles/scss/media";

.container {
  display: flex;
  flex: 1;
  padding-left: $grid-base-value * 4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  font-size: $font-size-0;

  @include sm {
    font-size: $font-size--1;
  }
}
