.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-right: 0;
  padding-left: 0;
  color: white;
  background-color: #172B4D;
  flex-direction: column;

  .loader {
    display: flex;
    align-items: center;
    justify-items: center;
  }

}
