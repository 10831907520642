@import "../../scss/typography";
@import "../../scss/constants";
@import '@restream/styles/scss/media';

.container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 3px $grid-base-value * 4 $grid-base-value * 2;

  h3 {
    font-size: $font-size-1;
    margin: 0;
    padding: 0;
  }
}

.accountSelectWrapper {
  @include xs {
    display: none;
  }
}
