@import "src/next/scss/constants";

.container {
  display: contents;
}

.chart-left {
  grid-column: 1 / span 6;
  display: flex;
  padding-left: $grid-base-value;
}

.chart-right {
  grid-column: 7 / span 6;
  display: flex;
  padding-right: $grid-base-value;
}

.table {
  grid-column: 1 / -1;
  padding: 0 $grid-base-value;
}

$default-column-width: minmax(100px, 221px);

.table-incoming {
  grid-template-columns: repeat(8, auto)
}

.table-outgoing {
  grid-template-columns: 64px auto repeat(4, $default-column-width)
}
