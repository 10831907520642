@import '@restream/ui-kit/style.css';
@import '@restream/website-dashboard-sdk/style.css';
@import '@restream/account-select/index.css';
@import "next/scss/typography";

div.rs1-layout-content {
  min-height: 100%;
  font-size: 16px;
  line-height: 1;
}

html {
  box-sizing: border-box;
  font-family: 'Graphik', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #172B4D;
}

button {
  font-family: inherit
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 16px;
  background-image: linear-gradient(to bottom,
          red 0%,
          transparent 7%,
          transparent 100%);
  pointer-events: none;
  display: none;
}


header {
  padding: 14px 24px;
  text-align: center;
  font-size: 12px;
  color: white;
  width: 100%;
}

main {
  flex: 1;
  width: 100%;
}

nav {
  background-color: #f90;
  padding: 20px;
}

aside {
  padding: 20px;
  background-color: #936;
}

footer {
  grid-column: span 3;
  padding: 30px;
  text-align: center;
  font-size: 14px;
  background-color: #690;
  color: white;
  max-width: 1440px;
}

h1 {
  font-size: 48px;
  line-height: 1.25;
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
}

h3 {
  font-size: 32px;
  line-height: 1.5;
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
}

h4 {
  font-size: 24px;
  line-height: 1.4;
  color: white;
  margin-bottom: 18px;
  font-weight: 500;
}

h5 {
  font-size: 18px;
  line-height: 1.66;
  color: #EBECF0;
  margin-bottom: 12px;
  font-weight: 200;
}

p {
  font-size: 18px;
  line-height: 1.6;
  padding: 10px 0;
  color: #A5ADBA;
}

a {
  color: #4C9AFF;
  text-decoration: none;
}

.link {
  color: #4C9AFF;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.link:hover {
  box-shadow: inset 0 -1px 0 0 #4C9AFF;
}
