.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  position: absolute;
  width: 100px;
  height: 100px;
}

@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lds-eclipse {
  position: relative;
}
.lds-eclipse div {
  position: absolute;
  -webkit-animation: lds-eclipse 2.1s linear infinite;
  animation: lds-eclipse 2.1s linear infinite;
  width: 180px;
  height: 180px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #FF5230;
  -webkit-transform-origin: 90px 92px;
  transform-origin: 90px 92px;
}
.lds-eclipse {
  width: 120px !important;
  height: 120px !important;
  -webkit-transform: translate(-60px, -60px) scale(0.6) translate(60px, 60px);
  transform: translate(-60px, -60px) scale(0.6) translate(60px, 60px);
}
