@import "../../scss/colors";
@import "../../scss/typography";
@import "../../scss/constants";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: $fg-color-darker-2;
  font-size: $font-size--1;
  margin-bottom: $grid-base-value;
}

.progress {
  height: $grid-base-value;
}
