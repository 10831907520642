@import "../../../scss/constants";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  width: 28px;
  margin-right: $grid-base-value;
}
