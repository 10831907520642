@import "../../scss/constants";

.container {
  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-template-rows: auto auto auto 480px auto;
  grid-gap: $grid-base-value;
  width: 100%;
  padding-bottom: $grid-base-value * 4;
}

.header {
  grid-column: 1 / span 2;
}

.tabs {
  grid-column: 1 / span 2;
}

.selector {
  grid-column: 1 / span 2;
  padding: $grid-base-value;
  padding-left: $grid-base-value * 3;
}

.selectors {
  display: flex;
  flex: 1;
}

.chart-line {
  grid-column: 1 /  span 2;
  padding: 0 $grid-base-value;
  display: flex;
}

.table {
  grid-column: 1 /  span 2;
  padding: 0 $grid-base-value;
}
