.col {
  display: flex;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
    &-reverse {
      flex-direction: column-reverse;
    }
  }

  &-fluid {
    width: 100%;
  }
}

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12,
.col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-offset-0, .col-sm-offset-1, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12,
.col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-offset-0, .col-md-offset-1, .col-md-offset-2, .col-md-offset-3, .col-md-offset-4, .col-md-offset-5, .col-md-offset-6, .col-md-offset-7, .col-md-offset-8, .col-md-offset-9, .col-md-offset-10, .col-md-offset-11, .col-md-offset-12,
.col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-offset-0, .col-lg-offset-1, .col-lg-offset-2, .col-lg-offset-3, .col-lg-offset-4, .col-lg-offset-5, .col-lg-offset-6, .col-lg-offset-7, .col-lg-offset-8, .col-lg-offset-9, .col-lg-offset-10, .col-lg-offset-11, .col-lg-offset-12,
.col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-offset-0, .col-xl-offset-1, .col-xl-offset-2, .col-xl-offset-3, .col-xl-offset-4, .col-xl-offset-5, .col-xl-offset-6, .col-xl-offset-7, .col-xl-offset-8, .col-xl-offset-9, .col-xl-offset-10, .col-xl-offset-11, .col-xl-offset-12,
.col-xm, .col-xm-1, .col-xm-2, .col-xm-3, .col-xm-4, .col-xm-5, .col-xm-6, .col-xm-7, .col-xm-8, .col-xm-9, .col-xm-10, .col-xm-11, .col-xm-12, .col-xm-offset-0, .col-xm-offset-1, .col-xm-offset-2, .col-xm-offset-3, .col-xm-offset-4, .col-xm-offset-5, .col-xm-offset-6, .col-xm-offset-7, .col-xm-offset-8, .col-xm-offset-9, .col-xm-offset-10, .col-xm-offset-11, .col-xm-offset-12,
.col-xxl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-offset-0, .col-xxl-offset-1, .col-xxl-offset-2, .col-xxl-offset-3, .col-xxl-offset-4, .col-xxl-offset-5, .col-xxl-offset-6, .col-xxl-offset-7, .col-xxl-offset-8, .col-xxl-offset-9, .col-xxl-offset-10, .col-xxl-offset-11, .col-xxl-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 100%;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 100%;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;

  &-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  &-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  &-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  &-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  &-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  &-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  &-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  &-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  &-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  &-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  &-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  &-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}


@media only screen and (min-width: 576px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    &-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
    }
    &-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
    }
    &-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }
    &-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }
    &-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
    }
    &-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }
    &-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
    }
    &-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
    }
    &-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

}

@media only screen and (min-width: 768px) {
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-offset-0, .col-md-offset-1, .col-md-offset-2, .col-md-offset-3, .col-md-offset-4, .col-md-offset-5, .col-md-offset-6, .col-md-offset-7, .col-md-offset-8, .col-md-offset-9, .col-md-offset-10, .col-md-offset-11, .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    &-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
    }

    &-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
    }

    &-3 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }

    &-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }

    &-6 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
    }

    &-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }

    &-9 {
      flex-basis: 75%;
      max-width: 75%;
    }

    &-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
    }

    &-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
    }

    &-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 992px) {
  .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-offset-0, .col-lg-offset-1, .col-lg-offset-2, .col-lg-offset-3, .col-lg-offset-4, .col-lg-offset-5, .col-lg-offset-6, .col-lg-offset-7, .col-lg-offset-8, .col-lg-offset-9, .col-lg-offset-10, .col-lg-offset-11, .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    &-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
    }
    &-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
    }
    &-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }
    &-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }
    &-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
    }
    &-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }
    &-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
    }
    &-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
    }
    &-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-offset-0, .col-xl-offset-1, .col-xl-offset-2, .col-xl-offset-3, .col-xl-offset-4, .col-xl-offset-5, .col-xl-offset-6, .col-xl-offset-7, .col-xl-offset-8, .col-xl-offset-9, .col-xl-offset-10, .col-xl-offset-11, .col-xl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    &-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
    }
    &-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
    }
    &-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }
    &-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }
    &-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
    }
    &-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }
    &-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
    }
    &-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
    }
    &-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

}

@media only screen and (min-width: 1400px) {
  .col-xm, .col-xm-1, .col-xm-2, .col-xm-3, .col-xm-4, .col-xm-5, .col-xm-6, .col-xm-7, .col-xm-8, .col-xm-9, .col-xm-10, .col-xm-11, .col-xm-12, .col-xm-offset-0, .col-xm-offset-1, .col-xm-offset-2, .col-xm-offset-3, .col-xm-offset-4, .col-xm-offset-5, .col-xm-offset-6, .col-xm-offset-7, .col-xm-offset-8, .col-xm-offset-9, .col-xm-offset-10, .col-xm-offset-11, .col-xm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-xm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    &-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
    }
    &-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
    }
    &-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }
    &-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }
    &-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
    }
    &-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }
    &-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
    }
    &-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
    }
    &-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

}

@media only screen and (min-width: 1600px) {
  .col-xxl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-offset-0, .col-xxl-offset-1, .col-xxl-offset-2, .col-xxl-offset-3, .col-xxl-offset-4, .col-xxl-offset-5, .col-xxl-offset-6, .col-xxl-offset-7, .col-xxl-offset-8, .col-xxl-offset-9, .col-xxl-offset-10, .col-xxl-offset-11, .col-xxl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-xxl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    &-1 {
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
    }
    &-2 {
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
    }
    &-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    &-4 {
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }
    &-5 {
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }
    &-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    &-7 {
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
    }
    &-8 {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }
    &-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    &-10 {
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
    }
    &-11 {
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
    }
    &-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

}




