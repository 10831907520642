$fg-color: rgba(255, 255, 255, 0.8);
$fg-color-darker-1: rgba(255, 255, 255, 0.5);
$fg-color-darker-2: rgba(255, 255, 255, 0.3);

$bg-color: #172B4D;
$bg-color-lighten-1: #213454;

$block-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

$error-color: #f24f4f
