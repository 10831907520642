.button {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  outline: 0;
  padding: 0 20px;
  border-radius: 4px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: all .2s;
  border-width: 0;
  box-sizing: border-box;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  display: inline-block;
  letter-spacing: .5px;
  height: 40px;
  cursor: pointer;

  &-size {
    &-big {
      height: 60px;
      font-size: 18px;
      line-height: 2.22;
    }

    &-medium {
      height: 32px;
      font-size: 13px;
      line-height: 1.53;
    }

    &-small {
      height: 40px;
      font-size: 15px;
      line-height: 2;
    }
  }

  &-type {
    &-input {
      background: #344563;
      color: #EBECF0;

      &:hover {
        color: white;
      }
    }

    &-circle {
      border-radius: 50%;
    }

    &-grey {
      background: #EBECF0;
      color: #97A0AF;

      &:hover {
        background: rgba(235, 236, 240, 0.91);
      }
    }

    &-info {
      background-color: #0065FF;
      color: #fff;
    }

    &-action {
      background: #FF5230;
      color: #fff;

      &:hover {
        background: #f76b4c;
      }
    }

    &-danger {
      background: none;
      color: #BF2600;
      border: 1px solid #BF2600;

      &:hover {
        background: #BF2600;
        color: white;
      }
    }

    &-close {
      background: none;
      border: 0;
      color: rgba(255, 255, 255, 0.5);
      font-size: 28px !important;
      line-height: 0 !important;

      &:hover {
        color: white;
      }
    }
    &-link {
      background: none;
      border: 0;
      color: rgba(255, 255, 255, 0.5);
      font-size: 28px !important;
      line-height: 0 !important;

      &:hover {
        color: white;
      }
    }
  }

  &-icons {
    &-copy {
      padding-left: 10px;

      svg {
        top: 2px;
        position: relative;

        .Master {
          opacity: .5;
          transition: .3s;
        }
      }

      &:hover {
        svg .Master {
          opacity: 1;
        }
      }
    }

    &-close {
      padding-left: 10px;

      &:hover {
        svg {
          g {
            opacity: 1;
            transition: .3s;
          }
        }
      }
    }

    &-docs {
      padding-left: 40px;

      &:before {
        background: url(./img/icon-docs-24px.svg) no-repeat 50% 50%;
        width: 24px;
        height: 28px;
        border-radius: 0;
        border-left: 0;
        border-top: 0;
        content: '';
        display: inline-flex;
        justify-content: center;
        position: relative;
        top: 4px;
        left: -10px;
      }
    }

    &-manage {
      padding-left: 40px;

      &:before {
        background: url(./img/icon-manage-24px.svg) no-repeat 50% 50%;
        width: 28px;
        height: 25px;
        border-radius: 0;
        border-left: 0;
        border-top: 0;
        content: '';
        display: inline-flex;
        justify-content: center;
        position: relative;
        top: 4px;
        left: -10px;
      }
    }

  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5;
  }
}






