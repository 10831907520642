@use '@restream/styles/scss/media';

.accountSelectTriggerButton {
  width: unset;
  padding: 8px;

  max-width: 100%;

  @include media.md {
    max-width: calc(100% - 40px);
  }
}
