.dropdown-header {
  display: flex;
  flex: 1 1;
  padding: 0 14px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 40px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  position: absolute;
  overflow: auto;
  top: 42px;
  right: 0;
  background: #253858;
  transition: all .2s;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  z-index: 10;
}

.dropdown-item {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  font-size: 13px;
  background: #253858;
  cursor: pointer;

  &:hover {
    background: #4c9aff;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 6px;
  vertical-align: middle;
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  transition-property: transform;
  transition-duration: .2s;
}

.caret-open {
  transform: rotate(180deg)
}
