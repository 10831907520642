@import "../../scss/typography";
@import "../../scss/constants";
@import "../../scss/colors";
@import '@restream/styles/scss/media';


.table {
  width: 100%;

  display: grid;

  thead, tbody, tr {
    display: contents;
  }

  td {
    display: flex;
    font-size: $font-size-0;

    @include sm {
      font-size: $font-size--1;
    }

    transition: background-color 200ms;
    box-shadow: inset 0 0 0 1px #334563;
  }

  thead > tr:first-child > td {
    font-size: $font-size--1;
    font-weight: 500;
    text-transform: uppercase;
    color: $fg-color-darker-1;
    padding: $grid-base-value * 2 $grid-base-value * 2;

    @include sm {
      padding: $grid-base-value $grid-base-value * 2;
    }

  }

  tbody > tr > td {
    color: $fg-color;
    padding: $grid-base-value * 2 $grid-base-value * 2;

    @include sm {
      padding: $grid-base-value $grid-base-value * 2;
    }

    justify-content: flex-end;
    align-items: center;
  }

  tbody > tr.bold {
    font-weight: 500;
  }

  tbody > tr > td:first-child {
    color: $fg-color;
    padding: $grid-base-value * 2 $grid-base-value * 2;

    @include sm {
      padding: $grid-base-value $grid-base-value * 2;
    }

    align-items: center;
    justify-content: flex-end;
  }

  tbody > tr > td:nth-child(2) {
    justify-content: flex-start;
  }

  .loader {
    grid-column: 1/-1;
    justify-content: center !important;
    display: flex;
    min-height: $grid-base-value * 12;
  }
}

$default-column-width: minmax(100px, 221px);

.table__viewers-session {
  grid-template-columns: 48px auto repeat(3, $default-column-width)
}

.table__followers-session {
  grid-template-columns: 48px auto repeat(3, $default-column-width)
}

.table__chat-session {
  grid-template-columns: 48px auto repeat(2, $default-column-width)
}

.table__chat-trends-session {
  grid-template-columns: min-content 1fr repeat(1, $default-column-width);
}

.table__chat-range {
  grid-template-columns: min-content 1fr repeat(1, $default-column-width);
}

.table__chat-chatters-range {
  grid-template-columns: min-content 1fr;
}

.table__audience-range {
  grid-template-columns: min-content 1fr repeat(1, $default-column-width);
}


input.checkbox[type=checkbox] {
  width: $grid-base-value * 2;
  height: $grid-base-value * 2;
  background: $bg-color;
  appearance: none;
  outline: none;
  border: 1px solid $fg-color-darker-2;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  transition: all 200ms;
}

input.checkbox.checkbox__round {
  border-radius: 8px;
}

input.checkbox[type=checkbox]:hover {
  background: $bg-color-lighten-1
}

input.checkbox[type=checkbox]:disabled {
  cursor: not-allowed;
}

input.checkbox[type=checkbox]:checked:after {
  position: absolute;
  content: "\2713";
  font-size: $grid-base-value * 2;
  font-weight: 500;
  width: $grid-base-value * 2;
  height: $grid-base-value * 2;
  color: $fg-color;
  background: transparent;
  bottom: 1px;
}

input.checkbox[type=checkbox].checkbox__round:checked:after {
  content: "\2022";
  font-size: 17px;
  bottom: 0;
  left: 4px;
}
