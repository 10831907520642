@import "../../../scss/constants";

.container {
  background: #213454;
  display: flex;
  flex: 1;
  //@media (max-width: 1400px) {
  //  flex-direction: column;
  //}
  padding: $grid-base-value * 2;
}

.block {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  min-height: 40px;

  &_date {
    min-width: 200px;
  }

  &_duration {
    min-width: 153px;
  }
  @media (max-width: 1400px) {
    margin: 15px 0;
  }
}

.title {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  font-size: 0.8125rem;
}

.number {
  font-size: 1rem;
}
