@import "../../scss/constants";

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto auto 504px auto;
  grid-gap: $grid-base-value;
  width: 100%;
  padding-bottom: $grid-base-value * 4;
}

.header {
  grid-column: 1/-1;
}

.tabs {
  grid-column: 1/-1;
}

.selector {
  grid-column: 1/-1;
  padding: $grid-base-value $grid-base-value $grid-base-value $grid-base-value * 3;
}

.selectors {
  display: flex;
  flex: 1;
}

.chart-line {
  grid-column: 1 /  span 7;
  padding-left: $grid-base-value;
  display: flex;
}

.chart-heatmap {
  grid-column: 8 /  span 5;
  padding-right: $grid-base-value;
  display: flex;
}


.table {
  grid-column: 1 / -1;
  padding: 0 $grid-base-value;
}
