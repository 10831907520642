$color-secondary: #949aa7;
$color-primary: #ebedf2;
$color-background: #253858;


.table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px;
  grid-row-gap: 2px;

  &__extended {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 50px;
  }

  thead, tbody, tr {
    display: contents;
  }

  tr a {
    display: flex;
    align-items: center;
    color: $color-primary;
    padding: 20px 10px;
    width: 100%;
    height: 100%;
  }

  td {
    display: flex;
    font-size: 14px;
    transition: background-color 200ms;

    img {
      margin-left: 7px;
      margin-right: 7px;
    }
  }

  thead > tr:first-child > td {
    font-size: 14px;
    font-weight: 500;
    color: $color-secondary;
    padding-bottom: 10px;
  }

  thead > tr:last-child > .line {
    padding: 0;
  }

  tbody > tr:not(.live) > td {
    background: $color-background;
    align-items: center;
    cursor: pointer;
  }

  tbody > tr:not(.live).hover > td {
    background: lighten($color-background, 5%);
  }

  tbody > tr.live > td {
    padding: 20px 10px;
  }
}

.row {
  grid-column: span 4;
  min-height: 51px;
  justify-content: center;
}

.line {
  grid-column: span 5;

  &__extended {
    grid-column: span 6;
  }

  background: #253858;
  height: 2px;
  margin-bottom: 10px;
}

.error {
  color: red;
}

.link {
  justify-content: flex-end;

  a > img {
    transition: all .2s ease-in-out;
    cursor: pointer;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}

.cell-wth-margin {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.live {
  font-weight: 500;

  & > td {
    background: none;
    box-shadow: inset 0 2px 0 $color-background,
    inset 0 0 0 $color-background,
    inset 0 0 0 $color-background,
    inset 0 -2px 0 $color-background;

    &:first-child {
      box-shadow: inset 0 2px 0 $color-background,
      inset 2px 0 0 $color-background,
      inset 0 0 0 $color-background,
      inset 0 -2px 0 $color-background
    }

    &:last-child {
      box-shadow: inset 0 2px 0 $color-background,
      inset 0 0 0 $color-background,
      inset -2px 0 0 $color-background,
      inset 0 -2px 0 $color-background
    }
  }

  & > td:has(.cell-wth-margin) {
    position: relative;
  }

  &__point {
    position: absolute;
    top: 0;
    color: red;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-left: 10px;
  }
}



