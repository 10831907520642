@import "../../scss/typography";
@import "../../scss/constants";
@import "../../scss/colors";
@import "@restream/styles/scss/media";


.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #213454;
  border-radius: 4px;
  box-shadow: $block-shadow;

  .header {
    padding: $grid-base-value * 3;
    padding-bottom: $grid-base-value;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: $font-size--1;
    color: $fg-color-darker-1;

    .title {
      text-transform: uppercase;
      font-weight: 500;
    }

    .counters {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;

      .vr {
        border-left: 1px solid $fg-color-darker-1;
        height: $grid-base-value;
        margin: 0 $grid-base-value;
      }

      .counter {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: $grid-base-value * 4;

        .key {
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: $grid-base-value;
        }

        .value {
          margin-left: $grid-base-value / 2;
          font-size: $font-size-2;
          color: $fg-color;
        }
      }
    }
  }

  .placeholder {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: .9;

  }

  .chart {
    display: flex;
    flex: 1;
    padding: $grid-base-value * 2;
  }
}

.error {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-top: $grid-base-value * 2;
    font-size: $font-size--1;
    text-align: center;
    color: $error-color;
    line-height: normal;
  }
}
