@import "../../scss/constants";
@import "../../scss/colors";

.container {
  padding: $grid-base-value * 2;
  color: $fg-color-darker-1;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.active {
  color: $fg-color;
  box-shadow: inset 0 -2px 0 0 $fg-color;
}
