.container {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 10px;
  display: flex;
  padding-top: 1px;
}
