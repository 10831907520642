.layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-right: 0;
  padding-left: 0;
  color: white;
  background-color: #172B4D;
  flex: 1;
  align-items: center;
  justify-content: center;

  &-column {
    flex-direction: column;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  main {
    margin-bottom: 160px;
  }

  @media all and (max-width: 576px) {
    main {
      margin-left: 0px;
    }
  }
}

.error-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error-view__img {
  margin-bottom: 20px;
}

.error-view__img img {
  max-height: 300px;
}

.error-view__info {
  font-size: 16px;
  line-height: 1.2;
  color: #16294e;
  background: #00c7e8;
  max-width: 500px;
  min-width: 290px;
  padding: 16px 24px 30px;
  border: 2px dashed #16294e;
}

.error-view__info-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
  color: #16294e;
  margin-bottom: 20px;
  text-align: center;
}

.error-view__info-text {
  font-size: 16px;
  line-height: 1.2;
  color: rgba(22, 41, 78, 0.6);
  border-left: 3px solid pink;
  padding-left: 10px;
}
