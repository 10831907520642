.chart-error {
  display: flex;
  flex: 1 1;
  width: 100%;
  background: rgba(19, 39, 69, 0.8);
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.message {
  max-width: 350px;
  text-align: center;
  opacity: .8;
}
