@import "../../../../scss/constants";


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0;
  transition: opacity .2s;
  user-select: none;
  opacity: 0.5;

  &:hover {
    opacity: 1
  }

  img {
    width: $grid-base-value * 2;
    height: $grid-base-value * 2;
  }
}
