@import "../../../scss/constants";
@import "../../../scss/colors";
@import "../../../scss/typography";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;

  .list-item {
    padding: $grid-base-value;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $fg-color;
  }

  .list-item:first-child {
    padding-top: 0;
  }
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.chatter {
  display: flex;
  flex: 1;
}

.icon {
  width: $grid-base-value * 3;
  height: $grid-base-value * 3;
  margin-right: $grid-base-value * 2;
}
