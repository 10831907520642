@import "../../scss/constants";
@import "@restream/styles/scss/media";

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 240px);

  @media (max-width: #{$breakpoint-md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 240px);
  }

  @media (max-width: #{$breakpoint-sm}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 240px);
  }

  grid-gap: $grid-base-value;

  width: 80%;

  @media (max-width: #{$breakpoint-lg}) {
    width: 100%;
  }
  padding: $grid-base-value;
}

.block {
  display: flex;
}

