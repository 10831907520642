@import "../../next/scss/constants";
@import "../../next/scss/typography";

.analytics-dropdown {
  display: flex;
  justify-content: center;
  position: relative;
  height: 43px;
  width: 43px;
  margin-left: 1px;
}

.analytics-dropdown .dropdown-header {
  display: flex;
  flex: 1 1;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  background: #253858;
  //border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.analytics-dropdown .dropdown-list {
  display: flex;
  position: absolute;
  top: 49px;
  right: 0;
  width: 182px;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 1000;
  max-height: 600px;
  overflow: auto;
}

.analytics-dropdown .dropdown-list .dropdown-list-item {
  display: flex;
  align-items: center;
  padding: $grid-base-value * 1.5 $grid-base-value * 2;
  font-size: $font-size-0;
  background: #253858;
  cursor: pointer;
}

.analytics-dropdown .dropdown-list .dropdown-list-item:hover {
  background: #4c9aff;
}

.analytics-dropdown .dropdown-list .dropdown-list-item span {
  margin-right: 20px;
  padding: 0;
  width: 15px;
  height: 15px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 8px solid;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  transition-property: transform;
  transition-duration: 0.2s;
}

.caret__open {
  transform: rotate(180deg);
}

.block-input {
  display: flex;
  max-height: 40px;
}

.block-input-item {
  margin-right: 6px;
}

.block-input-item .form-group-input-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-input-item .form-group-input-select label {
  margin-bottom: 0;
  padding: 10px 15px;
  background-color: #3b4560 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.block-input-item .form-group-input-select select.form-control {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-right: 40px;
  background-color: #3b4560;
  background-size: 9px;
}

.block-input-item .btn-default {
  background-color: #3b4560;
}

.block-input-item:last-child {
  margin-right: 0;
}

.datapicker-input-daterange {
  display: flex;
  flex-direction: row;
  max-width: 426px;
  min-height: 32px;
}

.datapicker-input-daterange input[name="start"] {
  min-width: 173px;
}

.datapicker-input-daterange input[name="end"] {
  min-width: 145px;
}

.datapicker-input-daterange .dropdown-in-datapiker {
  max-width: 100%;
  cursor: pointer;
  padding: 0;
}

.datapicker-input-daterange .dropdown-in-datapiker .dropdown-toggle {
  padding: 8px 12px 8px 0;
  height: 40px;
  display: flex;
  align-items: center;
}

.datapicker-input-daterange .dropdown-in-datapiker .dropdown-toggle .text-selected-period {
  padding: 0 15px 0 0;
  min-width: 120px;
  text-align: left;
}

.datapicker-input-daterange .dropdown-in-datapiker .dropdown-menu {
  right: 0;
  left: auto;
  width: 184px;
}

.datapicker-input-daterange .dropdown-in-datapiker .dropdown-menu .icon-calendar {
  background-color: rgba(16, 20, 28, 0) !important;
}

.datapicker-input-daterange .dropdown-in-datapiker .dropdown-menu .icon-calendar img {
  margin-right: 10px;
}

.datapicker-input-daterange .icon-calendar, .datapicker-input-daterange .icon-separator {
  color: #fff !important;
  height: 40px;
  padding: 8px 12px;
  width: 40px;
  align-items: center;
  display: flex;
}

.datapicker-input-daterange .input-group-addon {
  background-color: #3b4560;
  color: white !important;
  padding: 0;
}

.datapicker-input-daterange .form-control {
  background-color: #3b4560;
  color: white !important;
  border-radius: 0 !important;
  text-align: center !important;
}

.datapicker-input-daterange .form-control:hover {
  background-color: #3b4560;
}

.datapicker-input-daterange .form-control:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.datapicker-input-daterange .form-control:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.datapicker-input-daterange[disabled] {
  opacity: 0.2;
}

.datapicker-input-daterange[disabled] .dropdown-menu_datapiker {
  display: none;
}

.datapicker-input-daterange[disabled] .stream-log-item-title-span i {
  display: none;
}

.datapicker-input-daterange[disabled] > li > a {
  cursor: not-allowed !important;
}

.datapicker-input-daterange[disabled] .dropdown > a:hover {
  opacity: 0.6 !important;
}

.trunc {
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
