.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  //background: #253858;
  border: 0 none;
  transition: .3s;
  font-size: 16px;
  height: 40px;
  position: relative;
  border-radius: 4px;
}

.dropdown-header {
  display: flex;
  flex: 1 1;
  padding: 12px 14px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  position: absolute;
  overflow: auto;
  padding: 12px;
  top: 42px;
  right: 0;
  background: #253858;
  transition: all .2s;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  z-index: 10;
}

.dropdown-item {
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 13px;
  background: #253858;
  cursor: pointer;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  transition-property: transform;
  transition-duration: .2s;
}

.caret-open {
  transform: rotate(180deg)
}
